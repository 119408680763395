import { Transition } from '@headlessui/react'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ErrorEventEmitter } from 'utils/errorHandling'
import Toast, { ToastType } from '~components/ui/Toast/Toast'

interface IToastContext {
  toast: (content: string, duration?: number, type?: ToastType) => void
}

export const ToastContext = React.createContext<IToastContext>({
  toast: () => undefined,
})

export default function ToastProvider({ children }: { children: ReactNode }) {
  const [queue, setQueue] = useState<Array<{ content: string; type: ToastType }>>([])
  const [isShowing, setIsShowing] = useState(false)
  const [current, setCurrent] = useState<{ content: string; type: ToastType } | null>(null)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // Handle error events at the provider level
  useEffect(() => {
    const unsubscribe = ErrorEventEmitter.subscribe((error, context) => {
      if (context?.notifyUser) {
        setQueue((prev) => [...prev, { content: error.message, type: 'error' }])
      }
    })
    return unsubscribe
  }, [])

  // Process queue
  useEffect(() => {
    if (!isShowing && queue.length > 0) {
      const [next, ...rest] = queue
      setCurrent(next)
      setQueue(rest)
      setIsShowing(true)

      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(() => {
        setIsShowing(false)
        setCurrent(null)
      }, 5000)
    }
  }, [queue, isShowing])

  const toast = useCallback((content: string, duration = 5000, type: ToastType = 'info') => {
    setQueue((prev) => [...prev, { content, type }])
  }, [])

  const value = useMemo(() => ({ toast }), [toast])

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Transition
        as="div"
        show={isShowing && current !== null}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {current && <Toast type={current.type}>{current.content}</Toast>}
      </Transition>
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext).toast
