import { ApolloServerErrorCode } from '@apollo/server/errors'
import * as Sentry from '@sentry/nextjs'
import LogRocket from 'logrocket'

export type ErrorContext = {
  errorCode?: unknown // Most likely ApolloServerErrorCode, but could be anything
  isAsync?: boolean
  notifyUser?: boolean
  critical?: boolean
  operationName?: string // For GraphQL operations
  throwError?: boolean
}

const isReportableError = (errorCode?: unknown) => {
  return ![ApolloServerErrorCode.BAD_REQUEST, ApolloServerErrorCode.BAD_USER_INPUT].includes(
    errorCode as ApolloServerErrorCode
  )
}

export function globalErrorHandler(error: Error, context?: ErrorContext): void {
  if (isReportableError(context?.errorCode)) {
    // Log the error to Sentry
    Sentry.captureException(error, {
      tags: {
        isAsync: context?.isAsync,
      },
      extra: {
        // Include the operation name for GraphQL errors
        operationName: context?.operationName,
      },
    })
  }

  // Log the error to LogRocket
  LogRocket.captureException(error)

  // Publish the error event
  ErrorEventEmitter.publish(error, context)

  if (process.env.APP_ENV !== 'production') {
    console.error(error)
  }

  if (context?.throwError) {
    throw error
  }
}

type Subscriber = (error: Error, context?: ErrorContext) => void
type Unsubscribe = () => void

export class ErrorEventEmitter {
  private static subscribers: Subscriber[] = []

  public static subscribe(subscriber: Subscriber): Unsubscribe {
    this.subscribers.push(subscriber)

    // Return an unsubscribe function
    return () => {
      this.subscribers = this.subscribers.filter((sub) => sub !== subscriber)
    }
  }

  public static publish(error: Error, context?: ErrorContext) {
    this.subscribers.forEach((subscriber) => subscriber(error, context))
  }
}
