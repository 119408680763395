import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import {
  Fragment,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

interface Props {
  content: ReactNode
  children: ReactNode
  placement?: 'top' | 'bottom' | 'left' | 'right'
  alignment?: 'start' | 'center' | 'end'
  background?: 'dark' | 'light'
  inset?: boolean
  open?: boolean
  className?: string
  keepOpenOnHover?: boolean
  usePortal?: boolean
}

export type TooltipRef = {
  setTooltipOpen: (value: boolean) => void
}

const Tooltip = forwardRef<TooltipRef, Props>((props, ref) => {
  const {
    content,
    children,
    placement = 'bottom',
    alignment = 'center',
    background = 'dark',
    open = false,
    inset = false,
    className = '',
    keepOpenOnHover = false,
    usePortal = false,
  } = props

  const [mounted, setMounted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMounted(true)

      return () => {
        setMounted(false)
        setIsOpen(false)
      }
    }
    return undefined
  }, [])

  useImperativeHandle(ref, () => ({
    setTooltipOpen(value: boolean) {
      setIsOpen(value)
    },
  }))

  const handleMouseEnter = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const tooltipClasses = useMemo(
    () =>
      clsx(
        'absolute w-max whitespace-pre-line text-center p-2 bg-gray-50 text-gray-600 rounded-md shadow-lg border border-gray-200 focus:outline-none z-50',
        alignment === 'center' && ['top', 'bottom'].includes(placement) && '-translate-x-1/2',
        alignment === 'end' && ['top', 'bottom'].includes(placement) && 'right-0',
        background === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-600 border',
        className
      ),
    [alignment, placement, background, className]
  )

  const tooltipContent = useMemo(
    () => (
      <Transition
        as={Fragment}
        show={open || isOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={tooltipClasses}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {content}
        </div>
      </Transition>
    ),
    [
      open,
      isOpen,
      tooltipClasses,
      handleMouseEnter,
      handleMouseLeave,
      styles.popper,
      attributes.popper,
      content,
    ]
  )

  const containerClasses = useMemo(() => clsx('flex', !inset && 'relative'), [inset])

  return (
    <div className={containerClasses} onBlur={() => setIsOpen(false)}>
      {mounted && usePortal ? createPortal(tooltipContent, document.body) : tooltipContent}

      <div
        className="w-full"
        ref={setReferenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
    </div>
  )
})

Tooltip.displayName = 'Tooltip'
export default Tooltip
