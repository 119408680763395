import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import { CrewChangeOverViewTab } from '~pages/index'

export type ListOption = { id: string; name: string; selected: boolean }

export type FilterType = 'crewSupervisor' | 'crewOperator' | 'vessel'

export type FilterOptionsType = { [type in FilterType]: ListOption[] }

export type FilterOptionsStateType = Record<keyof typeof CrewChangeOverViewTab, FilterOptionsType>

const initialFilterState: FilterOptionsType = {
  crewSupervisor: [],
  crewOperator: [],
  vessel: [],
}
const initialFilterOptionsState: FilterOptionsStateType = {
  [CrewChangeOverViewTab.Completed]: initialFilterState,
  [CrewChangeOverViewTab.Cancelled]: initialFilterState,
  [CrewChangeOverViewTab.Clean]: initialFilterState,
  [CrewChangeOverViewTab.Archived]: initialFilterState,
}

const initialAllSelectedState: AllSelectedStateType = {
  crewSupervisor: false,
  crewOperator: false,
  vessel: false,
}

export type AllSelectedStateType = { [type in FilterType]: boolean }
const initialAllSelectedStateByTab: {
  [type in CrewChangeOverViewTab]: AllSelectedStateType
} = {
  [CrewChangeOverViewTab.Completed]: initialAllSelectedState,
  [CrewChangeOverViewTab.Cancelled]: initialAllSelectedState,
  [CrewChangeOverViewTab.Clean]: initialAllSelectedState,
  [CrewChangeOverViewTab.Archived]: initialAllSelectedState,
}

export interface IFilterContext {
  filters: FilterOptionsStateType
  setFilters: (type: FilterType, tab: CrewChangeOverViewTab, filters: ListOption[]) => void
  addFilters: (
    type: FilterType,
    filters: ListOption[],
    tab: CrewChangeOverViewTab,
    userId: string
  ) => void
  allFiltersSelected: { [type in CrewChangeOverViewTab]: AllSelectedStateType }
  toggleAllItems: (type: FilterType, tab: CrewChangeOverViewTab) => void
}

const initialContext: IFilterContext = {
  filters: initialFilterOptionsState,
  setFilters: () => undefined,
  addFilters: () => undefined,
  allFiltersSelected: initialAllSelectedStateByTab,
  toggleAllItems: () => undefined,
}

export const CrewChangeOverviewFilterContext = createContext<IFilterContext>(initialContext)

export default function CrewChangeOverviewFilterProvider({ children }: { children: ReactNode }) {
  const [currentUserId, setCurrentUserId] = useState<string | null>(null)
  const [filters, setFilters] = useState(initialContext.filters)

  const addFilters = useCallback(
    (type: FilterType, filtersToAdd: ListOption[], tab: CrewChangeOverViewTab, userId: string) => {
      const existingFilters = filters[tab][type]
      const newFilters = filtersToAdd
        .filter((v) => !existingFilters.some((o) => o.id === v.id))
        .map((f) => ({ ...f, selected: true }))
      // If the logged in user changes we need to reset the filters
      if (currentUserId !== userId) {
        const previous = initialContext.filters
        const setNew = {
          ...previous,
          [tab]: { ...previous[tab], [type]: [...newFilters] },
        }
        setFilters(setNew)
        setCurrentUserId(userId)
      } else {
        setFilters((prev) => {
          return {
            ...prev,
            [tab]: { ...prev[tab], [type]: [...newFilters] },
          }
        })
      }
    },
    [currentUserId, filters]
  )

  // Keeping track if all crew operator filters are selected
  const allFiltersSelected = useMemo(() => {
    const result = Object.values(CrewChangeOverViewTab).reduce((acc, tab) => {
      const filterTypeValues: FilterType[] = ['crewSupervisor', 'crewOperator', 'vessel']
      acc[tab] = filterTypeValues.reduce((filterAcc, filterType) => {
        // eslint-disable-next-line no-param-reassign
        filterAcc[filterType as FilterType] =
          filters[tab][filterType].length ===
          filters[tab][filterType].filter((f) => f.selected).length
        return filterAcc
      }, {} as { [type in FilterType]: boolean })
      return acc
    }, {} as { [type in CrewChangeOverViewTab]: AllSelectedStateType })
    return result
  }, [filters])

  // Toggles "Select All" and "Clear" buttons
  const toggleAllItems = useCallback(
    (type: FilterType, tab: CrewChangeOverViewTab) => {
      setFilters((prev) => ({
        ...prev,
        [tab]: {
          ...prev[tab],
          [type]: prev[tab][type].map((f) => ({ ...f, selected: !allFiltersSelected[tab][type] })),
        },
      }))
    },
    [allFiltersSelected]
  )

  const setFiltersWithType = useCallback(
    (type: FilterType, tab: CrewChangeOverViewTab, newFilters: ListOption[]) => {
      setFilters((prev) => ({
        ...prev,
        [tab]: { ...prev[tab], [type]: newFilters },
      }))
    },
    []
  )

  const context = useMemo(
    () => ({
      addFilters,
      allFiltersSelected,
      toggleAllItems,
      filters,
      setFilters: setFiltersWithType,
    }),
    [addFilters, allFiltersSelected, filters, setFiltersWithType, toggleAllItems]
  )

  return (
    <CrewChangeOverviewFilterContext.Provider value={context}>
      {children}
    </CrewChangeOverviewFilterContext.Provider>
  )
}
