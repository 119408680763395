import clsx from 'clsx'
import { ReactNode, SVGProps } from 'react'
import CheckmarkCircleIcon from '../Icon/CheckmarkCircleIcon'
import ErrorIcon from '../Icon/ErrorIcon'
import InfoIcon from '../Icon/InfoIcon'
import WarningIcon from '../Icon/WarningIcon'

export const variants = {
  default: {
    badge: 'text-gray-600 bg-gray-100 border-gray-200',
    icon: 'text-gray-500',
  },
  transparent: {
    badge: 'border-none',
    icon: '',
  },
  info: {
    badge: 'text-brand bg-blue-100  border-blue-200',
    icon: 'text-brand',
  },
  success: {
    badge: 'text-green-600 bg-green-100  border-green-200',
    icon: 'text-green-500',
  },
  danger: {
    badge: 'text-red-600 bg-red-100  border-red-200',
    icon: 'text-red-500',
  },
  warning: {
    badge: 'text-amber-500 bg-white  border-amber-500',
    icon: 'text-amber-500',
  },
  brand: {
    badge: 'text-white bg-brand border-brand',
    icon: 'text-white',
  },
  amber: {
    badge: 'text-amber-600 bg-amber-100 border-amber-200',
    icon: 'text-amber-500',
  },
  violet: {
    badge: 'text-violet-600 bg-violet-100 border-violet-200',
    icon: 'text-violet-500',
  },
  green: {
    badge: 'text-white bg-green-500 border-green-500',
    icon: 'text-green-500',
  },
  orange: {
    badge: 'text-orange-600 bg-orange-100 border-orange-600',
    icon: 'text-orange-500',
  },
  indigo: {
    badge: 'bg-indigo-50 border-indigo-50 text-brand',
    icon: 'bg-indigo-400',
  },
}

const badgeVariants = Object.keys(variants) as Array<keyof typeof variants>

export type BadgeVariant = (typeof badgeVariants)[number]

export interface BadgeProps {
  variant?: BadgeVariant
  children?: ReactNode
  className?: string
  slim?: boolean
  icon?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | null // Set to null to hide icon, set to icon to override, leave out to use default
  rightIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element // Icon to position at the right of the badge
}

const defaultIconForVariant: Partial<
  Record<BadgeVariant, ((props: SVGProps<SVGSVGElement>) => JSX.Element) | null>
> = {
  info: InfoIcon,
  success: CheckmarkCircleIcon,
  danger: ErrorIcon,
  warning: WarningIcon,
  default: null,
  brand: null,
}

function Badge({
  variant = 'default',
  slim = false,
  children,
  icon,
  rightIcon,
  className,
}: BadgeProps): JSX.Element {
  const BadgeIcon = icon === null ? null : icon ?? defaultIconForVariant[variant]
  const RightIcon = rightIcon

  return (
    <span
      className={clsx(
        className,
        'inline-flex items-center py-1 mx-0.5 text-xs leading-none border rounded-full font-semibold whitespace-nowrap',
        slim ? 'h-5' : 'h-8',
        {
          'px-1': RightIcon && BadgeIcon && children,
          'pl-1 pr-3': !RightIcon && BadgeIcon && children,
          'pl-3 pr-1': RightIcon && !BadgeIcon && children,
          'px-3': !RightIcon && !BadgeIcon && children,
          'py-3 px-1':
            (RightIcon && !BadgeIcon && !children) || (!RightIcon && BadgeIcon && !children),
        },
        variants[variant].badge
      )}
    >
      {BadgeIcon && (
        <span
          className={clsx(
            'flex items-center justify-center',
            slim ? 'h-4 w-4' : 'h-6 w-6',
            variants[variant].icon,
            {
              'mr-1.5': children,
            }
          )}
        >
          <BadgeIcon />
        </span>
      )}

      {children}

      {RightIcon && (
        <span
          className={clsx('flex items-center', slim ? 'h-4 w-4' : 'h-6 w-6', {
            'ml-1.5': children,
          })}
        >
          <RightIcon />
        </span>
      )}
    </span>
  )
}
export default Badge
