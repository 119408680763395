import clsx from 'clsx'
import { ClassAttributes, createElement, TdHTMLAttributes } from 'react'
import { TableVariant } from './Table'

interface TableCellProps
  extends TdHTMLAttributes<HTMLTableCellElement>,
    ClassAttributes<HTMLTableCellElement> {
  /** The HTML tag to use - either td or th */
  tagName?: 'td' | 'th'
  /** Whether the cell should shrink to content width */
  shrink?: boolean
  /** The table variant - either standalone or inline */
  variant?: TableVariant
}

function TableCell({
  tagName = 'td',
  shrink = false,
  variant = 'standalone',
  className,
  ...otherProps
}: TableCellProps): JSX.Element {
  const variantStyles = {
    inline: 'py-2 px-0',
    'inline-with-padding': 'py-2 px-4',
    standalone: `px-4 ${tagName === 'th' ? 'py-2' : 'py-4'}`,
  }

  const gridStyles = [
    'relative',
    '[&:not(:last-child)]:before:absolute',
    '[&:not(:last-child)]:before:right-0',
    '[&:not(:last-child)]:before:top-0',
    '[&:not(:last-child)]:before:bottom-0',
  ].join(' ')

  return createElement(tagName, {
    ...otherProps,
    className: clsx(variantStyles[variant], shrink && 'w-0', gridStyles, className),
  })
}

export default TableCell
