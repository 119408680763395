const fractionDigits: { [currency: string]: number } = {
  USD: 2,
  EUR: 2,
}

// while it is always 2 for now, we keep that to remove hardcoding from rest of the code
export function getFractionDigits(currency: string) {
  return fractionDigits[currency] ?? 2
}

interface FormatCurrencyOptions {
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name'
  numDecimals?: number
}

/** adds back the fraction digits, making amount 'real' one i.e. 1000 -> 10.00
 * used when moving amount FROM Tilla logic */
export function addFractionDigits(amount: number, currency: string) {
  return amount / 10 ** getFractionDigits(currency)
}

/** removes the fraction digits, making amount integer for precise storage/operations i.e. 10.00 -> 1000
 * used to get amount TO Tilla logic */
export function removeFractionDigits(amount: number, currency: string) {
  return parseInt((amount * 10 ** getFractionDigits(currency)).toFixed(), 10)
}

export const formatCurrency = (
  amountInSmallestSubdivision: number,
  currency: string,
  options: FormatCurrencyOptions = {}
): string => {
  const { currencyDisplay = 'narrowSymbol', numDecimals } = options

  const formatter = Intl.NumberFormat('en-us', {
    style: 'currency',
    currency,
    currencyDisplay,
  })

  const currencyExponent = numDecimals ?? formatter.resolvedOptions().maximumFractionDigits ?? 2

  return formatter.format(amountInSmallestSubdivision / 10 ** currencyExponent)
}
