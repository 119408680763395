import clsx from 'clsx'
import { ReactNode } from 'react'

export type TableVariant = 'standalone' | 'inline' | 'inline-with-padding'

interface TableProps {
  variant?: TableVariant
  children: ReactNode
  fixed?: boolean
  className?: string
  grid?: boolean
  overflowType?: 'hidden' | 'scroll' | 'visible'
  tableClassName?: string
}

const Table = ({
  variant = 'standalone',
  children,
  fixed = false,
  className,
  tableClassName,
  grid = false,
  overflowType = 'hidden',
}: TableProps): JSX.Element => {
  const wrapperClasses = clsx(
    'w-full bg-white rounded-lg',
    variant === 'standalone' && 'border border-gray-200 shadow-sm',
    grid && 'divide-y divide-gray-200',
    variant !== 'standalone' && `overflow-${overflowType}`,
    className
  )

  const tableClasses = clsx(
    'w-full',
    fixed && 'table-fixed',
    grid && [
      'border-collapse',
      '[&_tr]:divide-x [&_tr]:divide-gray-200',
      '[&_td]:divide-x [&_td]:divide-gray-200',
      '[&_th]:divide-x [&_th]:divide-gray-200',
    ],
    tableClassName
  )

  return (
    <div className={wrapperClasses}>
      <table className={tableClasses}>{children}</table>
    </div>
  )
}

export default Table
