import clsx from 'clsx'
import { ReactNode } from 'react'

function TableBody({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}): JSX.Element {
  return <tbody className={clsx('text-gray-800', className)}>{children}</tbody>
}

export default TableBody
