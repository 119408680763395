/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { FlightSegmentChangeFieldsFragmentDoc } from './flight-changes-gql.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type NotificationsQueryVariables = Types.Exact<{ [key: string]: never }>

export type NotificationsQuery = {
  notifications: Array<{
    bucketId: string
    changedSegmentsNotifications: Array<{
      __typename: 'ChangedSegmentsNotification'
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
      change: {
        id: string
        changeType: Types.FlightSegmentChangeType
        active: boolean
        bucketId: string
        flightBookingId: string
        flightNumber: number | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        flightSegment: {
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }
        departure: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        arrival: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }
    }>
    cheaperOfferNotifications: Array<{
      __typename: 'CheaperOfferNotification'
      seaman: { firstName: string; lastName: string }
      cheaperOffer: {
        flightBooking: { id: string; offer: { amount: number; currency: string } }
        flightOffer: { amount: number; currency: string }
      }
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
    }>
    vesselSchedule: {
      portName: string | null
      ETA: string
      seaport: { timezoneOlson: string | null }
    }
    vessel: { charterName: string }
  }>
}

export type UpdateNotificationsStateMutationVariables = Types.Exact<{
  notificationsToUpdate: Array<Types.NotificationToUpdate> | Types.NotificationToUpdate
  notificationsUpdateType?: Types.InputMaybe<Types.NotificationUpdateType>
}>

export type UpdateNotificationsStateMutation = {
  updateNotificationsState: Array<{
    bucketId: string
    changedSegmentsNotifications: Array<{
      __typename: 'ChangedSegmentsNotification'
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
      change: {
        id: string
        changeType: Types.FlightSegmentChangeType
        active: boolean
        bucketId: string
        flightBookingId: string
        flightNumber: number | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        flightSegment: {
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }
        departure: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        arrival: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }
    }>
    cheaperOfferNotifications: Array<{
      __typename: 'CheaperOfferNotification'
      seaman: { firstName: string; lastName: string }
      cheaperOffer: {
        flightBooking: { id: string; offer: { amount: number; currency: string } }
        flightOffer: { amount: number; currency: string }
      }
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
    }>
    vesselSchedule: {
      portName: string | null
      ETA: string
      seaport: { timezoneOlson: string | null }
    }
    vessel: { charterName: string }
  }>
}

export type NotificationFieldsFragment = {
  bucketId: string
  changedSegmentsNotifications: Array<{
    __typename: 'ChangedSegmentsNotification'
    notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
    change: {
      id: string
      changeType: Types.FlightSegmentChangeType
      active: boolean
      bucketId: string
      flightBookingId: string
      flightNumber: number | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      flightSegment: {
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }
      departure: {
        date: string | null
        time: string | null
        iata: string | null
        timezoneOlson: string | null
        countryAlpha2: string | null
        name: string | null
        terminal: string | null
        city: string | null
      } | null
      arrival: {
        date: string | null
        time: string | null
        iata: string | null
        timezoneOlson: string | null
        countryAlpha2: string | null
        name: string | null
        terminal: string | null
        city: string | null
      } | null
      marketingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
      operatingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
    }
  }>
  cheaperOfferNotifications: Array<{
    __typename: 'CheaperOfferNotification'
    seaman: { firstName: string; lastName: string }
    cheaperOffer: {
      flightBooking: { id: string; offer: { amount: number; currency: string } }
      flightOffer: { amount: number; currency: string }
    }
    notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
  }>
  vesselSchedule: {
    portName: string | null
    ETA: string
    seaport: { timezoneOlson: string | null }
  }
  vessel: { charterName: string }
}

export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on VesselNotifications {
    changedSegmentsNotifications {
      __typename
      notification {
        createdAt
        dismissed
        id
        read
      }
      change {
        ...FlightSegmentChangeFields
      }
    }
    cheaperOfferNotifications {
      __typename
      seaman {
        firstName
        lastName
      }
      cheaperOffer {
        flightBooking {
          id
          offer {
            amount
            currency
          }
        }
        flightOffer {
          amount
          currency
        }
      }
      notification {
        createdAt
        dismissed
        id
        read
      }
    }
    vesselSchedule {
      portName
      ETA
      seaport {
        timezoneOlson
      }
    }
    vessel {
      charterName
    }
    bucketId
  }
  ${FlightSegmentChangeFieldsFragmentDoc}
`
export const NotificationsDocument = gql`
  query Notifications {
    notifications {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export function useNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>
export const UpdateNotificationsStateDocument = gql`
  mutation UpdateNotificationsState(
    $notificationsToUpdate: [NotificationToUpdate!]!
    $notificationsUpdateType: NotificationUpdateType
  ) {
    updateNotificationsState(
      notificationsToUpdate: $notificationsToUpdate
      notificationsUpdateType: $notificationsUpdateType
    ) {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`
export type UpdateNotificationsStateMutationFn = Apollo.MutationFunction<
  UpdateNotificationsStateMutation,
  UpdateNotificationsStateMutationVariables
>

/**
 * __useUpdateNotificationsStateMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsStateMutation, { data, loading, error }] = useUpdateNotificationsStateMutation({
 *   variables: {
 *      notificationsToUpdate: // value for 'notificationsToUpdate'
 *      notificationsUpdateType: // value for 'notificationsUpdateType'
 *   },
 * });
 */
export function useUpdateNotificationsStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationsStateMutation,
    UpdateNotificationsStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateNotificationsStateMutation,
    UpdateNotificationsStateMutationVariables
  >(UpdateNotificationsStateDocument, options)
}
export type UpdateNotificationsStateMutationHookResult = ReturnType<
  typeof useUpdateNotificationsStateMutation
>
export type UpdateNotificationsStateMutationResult =
  Apollo.MutationResult<UpdateNotificationsStateMutation>
export type UpdateNotificationsStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationsStateMutation,
  UpdateNotificationsStateMutationVariables
>
