import clsx from 'clsx'
import { forwardRef, MouseEventHandler, ReactNode, SVGProps } from 'react'

export interface AppShellNavigationItemProps {
  isActive?: boolean
  center?: boolean
  children?: ReactNode
  onClick?: MouseEventHandler
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

const AppShellNavigationItem = forwardRef(
  (
    {
      isActive,
      center = true,
      children,
      onClick,
      icon: NavigationItemIcon,
    }: AppShellNavigationItemProps,
    ref
  ) => {
    return (
      <div
        className={clsx(
          'cursor-pointer h-full pt-0.5 border-b-2 flex border-transparent text-gray-800 font-semibold text-sm group',
          isActive ? 'text-brand !border-brand' : 'hover:text-blue-400 hover:border-blue-50',
          center ? 'items-center' : ''
        )}
        onClick={onClick}
      >
        {NavigationItemIcon && (
          <NavigationItemIcon
            className={clsx(
              'w-6 h-6 mr-2.5',
              isActive ? 'text-brand' : 'text-gray-600 group-hover:text-blue-400'
            )}
          />
        )}
        {children}
      </div>
    )
  }
)

AppShellNavigationItem.displayName = 'AppShellNavigationItem'
export default AppShellNavigationItem
