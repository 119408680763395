import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import clsx from 'clsx'
import { sortBy } from 'lodash'
import { useContext } from 'react'
import FormCheckbox from '~components/ui/Form/FormCheckbox'
import FunnelIcon from '~components/ui/Icon/FunnelIcon'
import {
  CrewChangeOverviewFilterContext,
  FilterType,
  ListOption,
} from '~context/CrewChangeOverviewFilterContext'
import { CrewChangeOverViewTab } from '~pages/index'

interface Props {
  type: FilterType
  title: string
  tab: CrewChangeOverViewTab
}

export default function CrewChangeListFilter({ type, title, tab }: Props) {
  const { setFilters, filters, allFiltersSelected, toggleAllItems } = useContext(
    CrewChangeOverviewFilterContext
  )

  const onChangeHandler = (selectedFilters: ListOption[]) => {
    setFilters(
      type,
      tab,
      filters[tab][type].map((f) => ({
        ...f,
        selected: !!selectedFilters.find((sf) => f.id === sf.id),
      }))
    )
  }

  return (
    <Listbox
      as="div"
      value={filters[tab][type].filter((f) => f.selected)}
      by="id"
      onChange={onChangeHandler}
      multiple
      className="text-sm"
    >
      {({ open }) => {
        return (
          <>
            <ListboxButton
              className={clsx(
                'flex cursor-pointer items-center gap-2 rounded-xl border-2 bg-white p-2',
                open && 'border-brand text-brand'
              )}
            >
              <FunnelIcon className={clsx(open && 'text-brand')} strokeWidth={open ? 2 : 1.5} />
              <span>{title}</span>
              <span
                className={clsx(
                  'rounded-full bg-opacity-10 p-1 text-xxs',
                  open ? 'bg-brand text-white' : 'bg-black'
                )}
              >
                {filters[tab][type].filter((v) => v.selected).length}
              </span>
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              className="border-1 mt-4 min-w-[12rem] rounded-md border bg-white p-4 max-h-96 overflow-auto"
            >
              <div className="mb-2 flex justify-between space-x-2">
                <span className="text-sm font-semibold">{title} Name</span>
                <button
                  onClick={() => toggleAllItems(type, tab)}
                  type="button"
                  className={clsx(
                    'cursor-pointer text-sm font-semibold text-brand',
                    allFiltersSelected[tab][type] && 'text-red-600 hover:text-red-500'
                  )}
                >
                  {`${!allFiltersSelected[tab][type] ? 'Select all' : 'Clear'}`}
                </button>
              </div>
              {sortBy(filters[tab][type], 'name').map((v) => (
                <ListboxOption key={v.id} value={v}>
                  {/* Make component readonly as it is managed by the parent listbox */}
                  {({ selected }) => <FormCheckbox checked={selected} readOnly label={v.name} />}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </>
        )
      }}
    </Listbox>
  )
}
